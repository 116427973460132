<template lang="pug">
	.main-wrapper.especialista-listar
		header.main-wrapper-header
			.p-grid.p-align-center
				.p-col-12.ta-right
					h1.text-header.text-secondary MedClub / <b>Especialistas</b>

		Dialog(header='Remover especialista' :visible.sync='dialogApagar' :modal='true')
			p Deseja remover o especialista <b>{{ dialogApagar_data.nm_especialista }}</b>?
			.ta-center.my-4
				Button.p-button-danger(label='Remover' @click='remove()')

		Dialog.dialogUsuario(header='Cadastro de usuário' :visible.sync='dialogUsuario' :modal='true')
			.ta-center(v-if='dialogUsuario_data.waiting')
				ProgressSpinner.large-progress-spinner(strokeWidth='2')
			.panel-list(v-else-if='dialogUsuario_data.usuario')
				p <b>Especialista:</b> {{ dialogUsuario_data.nm_especialista }}
				p <b>Usuário: </b>
					span {{ dialogUsuario_data.usuario.username }}
					router-link.user-edit-link(:to="`/funcionario/salvar/${ dialogUsuario_data.usuario.id }/`") (Editar)
				p <b>Estabelecimentos:</b>
				p.ml-4(v-for='estabelecimento in dialogUsuario_data.usuario.estabelecimentos') - {{ estabelecimento.nm_fantasia }}
				.ta-right.mt-4
					ProgressSpinner.small-progress-spinner.mx-4(v-if='waitingDialogUsuario' strokeWidth='4')
					Button.p-button-danger(v-else label='Remover vínculo' icon='jam jam-trash' @click='removerUsuario()')
			div(v-else)
				p.ta-center Associar usuário a especialista <b>{{ dialogUsuario_data.nm_especialista }}</b>:
				.p-grid.p-fluid.my-4
					.p-col-12
						.p-inputgroup
							InputText(placeholder='Nome de usuário' v-model='dialogUsuario_data.username' @keyup.enter.native='adicionarUsuario()')
							button.p-button.p-component.px-4(v-if='waitingDialogUsuario' disabled)
								ProgressSpinner.small-progress-spinner(strokeWidth='6')
							div(v-else)
								Button(label='Salvar' @click='adicionarUsuario()')
		
		Panel.my-2(header='Filtros' :toggleable='true' :collapsed='windowInnerWidth < 576')
			.p-grid.p-fluid.p-align-end
				.p-col-12.p-md-3.p-lg-3
					label.form-label Nome do especialista:
					.p-inputgroup
						InputText(
							placeholder='Nome'
							@keyup.enter.native='applyFilters()'
							@input='checkEmpty_nm_especialista()'
							v-model='filters.nm_especialista'
						)
						Button(icon='jam jam-search' @click='applyFilters()')
				.p-col-12.p-md-3.p-lg-2
					label.form-label CPF:
					.p-inputgroup
						InputMask(
							mask='999.999.999-99'
							placeholder='CPF'
							:autoClear='false'
							v-model='filters.nr_cpf'
							@keydown.enter.native='applyFilters()'
						)
						Button(icon='jam jam-search' @click='applyFilters()')
				.p-col-12.p-md-3.p-lg-2
					label.form-label Conselho:
					.p-inputgroup
						InputText(
								placeholder='Conselho'
								@keyup.enter.native='applyFilters()'
								@input='checkEmpty_nm_especialista()'
								v-model='filters.nr_conselho'
							)
						Button(icon='jam jam-search' @click='applyFilters()')
				.p-col-12.p-md-4
					MultiSelectEspecialidades(
						@lista-especialidades='mudaEspecialidades'
					)
				.p-col-12.p-md-1
					label.form-label Status:
						Dropdown(
							v-model='filters.ie_status'
							:options='filtersOptions.ieStatus'
							optionLabel='text'
							optionValue='value'
							dataKey='value'
							placeholder='TODOS'
							@change='applyFilters()'
						)

		.ta-right.my-2
			Button(label='Adicionar' icon="jam jam-plus" @click="$router.push('/especialista/salvar/0/')")

		ProgressBar(v-if='waiting' mode="indeterminate")
		div(v-else-if='list.length == 0')
			p.ta-center.text-secondary(style='margin-top: 40px;') Nenhum registro encontrado.
		div(v-else)
			Paginator(:rows='paginator.per_page' :first='(paginator.page - 1) * paginator.per_page' :totalRecords='paginator.count' @page="onPage($event)")
			DataView.my-2(:value="list" layout="grid")
				template(#grid="props")
					.p-col-12.p-md-4(style="padding: .5em" :class="{ disabled: !props.data.ie_status }")
						Panel.panel-list.ta-center(:header="props.data.nm_especialista" style='position: relative')
							.status-indicator(:class='{ off: props.data.ie_status == false }' v-tooltip.top="props.data.ie_status ? 'Habilitado' : 'Desabilitado'")
								span(:class="{ 'jam-check': props.data.ie_status == true, 'jam-close': props.data.ie_status == false  }").jam
							.p-grid.p-fluid.p-align-center
								.p-col-3
									.avatar-wrapper
										img(:src='props.data.aq_foto.split("/").pop() == "foto-default.jpeg" ?\
											require("./../../assets/img/profile-pic-1.jpg") :\
											`${ mediaUrl }${ props.data.aq_foto }`')
								.p-col-9.ta-left
									p <b>Nome:</b> {{ props.data.nm_especialista }}
									p <b>Conselho:</b> {{ props.data.nm_conselho }} {{ props.data.nr_conselho }}
									p(v-tooltip.top="props.data.ds_email" style='cursor: pointer') <b>E-mail:</b> {{ props.data.ds_email_f }}
									p <b>Cadastro de usuário:</b> {{ props.data.cd_usuario_django ? 'sim' : 'não' }}
							.ta-right.p-buttonset.mt-3
								Button.mr-1.p-button-raised.p-button-rounded.p-button-info(icon="jam jam-eye-f" @click='visualizar(props.data.id)')
								Button.mr-1.p-button-raised.p-button-rounded.p-button-info(icon="jam jam-user-plus"
									@click="dialogUsuario_data = props.data; visualizarUsuario()")
								Button.mr-1.p-button-raised.p-button-rounded(icon="jam jam-write"
									@click="$router.push(`/especialista/salvar/${ props.data.id }/`)")
								Button.p-button-raised.p-button-rounded.p-button-danger(icon="jam jam-minus-circle"
									@click="dialogApagar = true; dialogApagar_data = props.data")
			Paginator(:rows='paginator.per_page' :first='(paginator.page - 1) * paginator.per_page' :totalRecords='paginator.count' @page="onPage($event)")
</template>

<style lang="scss">
	.especialista-listar {
		.avatar-wrapper {
			overflow: hidden;
			border-radius: 50%;
			font-size: 0;
			img {
				width: 100%;
			}
		}
		.disabled {
			.avatar-wrapper {
				background-color: #888;
				img { opacity: 50%; }
			}
			p { color: #aaa; }
			.p-panel-titlebar {
				span { color: #aaa; }
			}
			.p-panel-content {
				background-color: #f4f4f4 !important;
			}
		}
		.dialogUsuario {
			@media all and (min-width: 577px) {
				max-width: 480px;
				width: 100%;
			}
			.large-progress-spinner {
				width: 80px;
				margin: 40px auto;
			}
			.small-progress-spinner {
				width: 25px;
				height: auto;
			}
			.user-edit-link {
				margin-left: 4px;
				font-size: 12px;
				&:hover {
					text-decoration: underline;
				}
			}
		}
		.status-indicator {
			display: inline-block;
			font-size: 0;
			width: 20px;
			height: 20px;
			line-height: 20px;
			border-radius: 50%;
			position: absolute;
			top: 9px;
			right: 9px;
			color: #fff;
			background-color: #94c860;
			border: 1px solid #84b553;
			.jam {
				font-size: 18px;
				width: 100%;
				text-align: center;
			}
			&.off {
				background-color: #d33838;
				border: 1px solid #b33030;
			}
		}
	}
	.p-tooltip {
		max-width: none;
	}
</style>

<script>
	import DataView from 'primevue/dataview'
	import Panel from 'primevue/panel'
	import Button from 'primevue/button'
	import ProgressBar from 'primevue/progressbar'
	import ProgressSpinner from 'primevue/progressspinner'
	import Dialog from 'primevue/dialog'
	import InputText from 'primevue/inputtext'
	import Tooltip from 'primevue/tooltip'
	import Paginator from 'primevue/paginator'
	import Dropdown from 'primevue/dropdown'

	import wsConfigs from './../../middleware/configs'
	import { Especialista, Funcionario } from './../../middleware'
	import InputMask from 'primevue/inputmask'
	import MultiSelectEspecialidades from '../CustomComponents/MultiSelectEspecialidades.vue';
	export default {

		beforeRouteEnter (to, from, next) {
			next(vm => {
				let routeName = vm.$route.path.split('/')[1]
				if ((from.path.split('/')[1] === routeName || from.path.split('/')[1] === 'estabelecimentos') && vm.$store.state.filters)
					vm.filters = vm.$store.state.filters
				vm.$store.commit('clearFilters')
				vm.applyFilters()
			})
		},
		beforeRouteLeave (to, from, next) {
			let routeName = this.$route.path.split('/')[1]
			if (to.path.split('/')[1] === routeName)
				if (this.filters) this.$store.commit('setFilters', this.filters)
			next()
		},
		created () {
			// this.applyFilters()
		},
		components: {
			DataView, Panel, Button, ProgressBar, Dialog, 
			InputText, ProgressSpinner, Tooltip, Paginator,
			InputMask, MultiSelectEspecialidades, Dropdown
		},
		directives: { tooltip: Tooltip },
		data () {
			return {
				list: [],
				windowInnerWidth: window.innerWidth,
				waiting: false,
				waitingDialogUsuario: false,
				dialogApagar: false,
				dialogUsuario: false,
				dialogApagar_data: {},
				dialogUsuario_data: {},
				filters: {
					nm_especialista: '',
					nr_cpf: '',
					nr_conselho: '',
					cd_especialidades: '',
					ie_status: 'true'
				},
                filtersOptions: {
                    ieStatus: [
                        { text: "TODOS", value: null },
                        { text: "Ativo", value: 'true' },
                        { text: "Inativo", value: 'false' },
                    ]
                },
				paginator: {
                    page: this.$route.query.pg ? this.$route.query.pg : 1,
					per_page: 24,
					count: 0
				},
				mediaUrl: wsConfigs.getMediaUrl()
			}
		},
		methods: {
			mudaEspecialidades(dado) {
                this.filters.cd_especialidades = dado
                this.applyFilters()
            },
			getList (params) {
				this.waiting = true
				return Especialista.findAll(params).then(response => {
					this.waiting = false
					if (response.status == 200) {
						this.paginator.count = response.data.count      
						response.data.results.forEach(especialista => {
							let strMaxLength = 24
							especialista.ds_email = especialista.ds_email === null ? "" : especialista.ds_email
							especialista.ds_email_f = especialista.ds_email.length > strMaxLength ?
								`${ especialista.ds_email.substr(0, strMaxLength) }...` : especialista.ds_email
						})
						this.list = response.data.results
					}
					return true
				})
			},
			applyFilters () {
				let params = { paginacao: true, page: this.paginator.page, per_page: this.paginator.per_page }

				if (this.$route.query.pg != this.paginator.page) this.$router.replace( { query: { pg: this.paginator.page } } )

				Object.keys(this.filters).forEach((key) => {
					if (this.filters[key]) params[key] = this.filters[key]

					if (key == 'nr_cpf') {
						params[key] = this.filters[key].replace(/\D/g, '')
					}
				})

				return this.getList(params)
			},
			onPage (ev) {
				this.paginator.page = ev.page + 1
				this.applyFilters()
			},
			visualizar (id) {
				this.$router.push({ path: `/especialista/visualizar/${ id }/` })
			},
			remove () {
				Especialista.remove(this.dialogApagar_data.id).then(response => {
					if (response.status == 200) {
						this.$toast.info('Especialista removido com sucesso', { duration: 3000 })
						this.dialogApagar = false
						this.applyFilters()
					}
				})
			},
			visualizarUsuario () {
				this.dialogUsuario = true
				if (this.dialogUsuario_data.cd_usuario_django) {
					this.dialogUsuario_data.waiting = true
					Funcionario.find(this.dialogUsuario_data.cd_usuario_django).then(response => {
						if (response.status == 200) this.dialogUsuario_data.usuario = response.data
						this.dialogUsuario_data.waiting = false
						this.$forceUpdate()
					})
				} else this.dialogUsuario_data.usuario = null
			},
			adicionarUsuario () {
				if (!this.dialogUsuario_data.username) {
					this.$toast.error('Digite o nome de usuario', { duration: 3000 })
					return
				}

				this.waitingDialogUsuario = true
				Especialista.addUsuario(this.dialogUsuario_data.id, this.dialogUsuario_data.username).then(response => {
					this.waitingDialogUsuario = false
					if (([200, 201]).includes(response.status)) {
						this.$toast.success('Usuário adicionado', { duration: 3000 })
						this.dialogUsuario = false
						this.applyFilters().then(() => {
							this.dialogUsuario_data.cd_usuario_django =
								this.list.find(e => e.id == this.dialogUsuario_data.id).cd_usuario_django
							this.visualizarUsuario()
						})
					} else if(response.data.non_field_errors)
						response.data.non_field_errors.forEach(errorMsg => {
							this.$toast.error(errorMsg, { duration: 3000 })
						})
				})
			},
			removerUsuario () {
				this.waitingDialogUsuario = true
				Especialista.removeUsuario(this.dialogUsuario_data.id).then(response => {
					this.waitingDialogUsuario = false
					if (response.status == 200) {
						this.$toast.info('Vínculo com usuário removido', { duration: 3000 })
						this.dialogUsuario = false
						this.applyFilters().then(() => {
							this.dialogUsuario_data.cd_usuario_django = null
							this.visualizarUsuario()
						})
					}
				})
			},
			checkEmpty_nm_especialista () {
				if (! this.filters.nm_especialista) this.applyFilters()
			}
		}
	}
</script>